import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetUsers, dispatchCreateUser } from '@/store/admin/actions';
let CreateUser = class CreateUser extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.fullName = '';
        this.email = '';
        this.isActive = true;
        this.isSuperuser = false;
        this.setPassword = false;
        this.password1 = '';
        this.password2 = '';
    }
    async mounted() {
        await dispatchGetUsers(this.$store);
        this.reset();
    }
    reset() {
        this.password1 = '';
        this.password2 = '';
        this.fullName = '';
        this.email = '';
        this.isActive = true;
        this.isSuperuser = false;
        this.$validator.reset();
    }
    cancel() {
        this.$router.back();
    }
    async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile = {
                email: this.email,
            };
            if (this.fullName) {
                updatedProfile.full_name = this.fullName;
            }
            if (this.email) {
                updatedProfile.email = this.email;
            }
            updatedProfile.is_active = this.isActive;
            updatedProfile.is_superuser = this.isSuperuser;
            updatedProfile.password = this.password1;
            await dispatchCreateUser(this.$store, updatedProfile);
            this.$router.push('/main/admin/users');
        }
    }
};
CreateUser = __decorate([
    Component
], CreateUser);
export default CreateUser;
